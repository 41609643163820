<template>
  <div
    class="p-3 border-[1px] rounded-lg flex flex-col self-baseline border-gray-300 dark:border-gray-700 w-full"
  >
    <div
      v-if="!props.isArticle"
      class="py-1 mb-2 text-center text-gray-500 bg-gray-200 dark:bg-gray-800 rounded-md font-bold"
    >
      {{ LL.about_us() }}
    </div>
    <UVerticalNavigation :links="links"/>
  </div>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";

const { LL } = typesafeI18n();

const props = defineProps({
  isArticle: {
    type: Boolean,
    default: false,
  },
});

const navigateBlank = (url: string) => () => {
  window.open(url, "_blank");
};

const youtube = computed(() => [
  {
    id: "youtube",
    label: "TechMely",
    icon: "i-simple-icons-youtube",
    click: navigateBlank("https://www.youtube.com/c/TechMely?sub_confirmation=1"),
    badge: `5K ${LL.value.follow()}`,
  },
]);

const discord = computed(() =>
  props.isArticle
    ? []
    : [
        {
          id: "discord",
          label: "Discord",
          icon: "i-simple-icons-discord",
          click: navigateBlank("https://discord.gg/PGERaWD4CQ"),
          badge: `2K ${LL.value.members()}`,
        },
      ]
);

const github = computed(() => [
  {
    id: "github",
    label: "TechMely",
    icon: "i-simple-icons-github",
    click: navigateBlank("https://github.com/techmely"),
    badge: `51 ${LL.value.follow()}`,
  },
]);

const facebook = computed(() => [
  {
    id: "techmely-fanpage",
    label: "TechMely Fanpage",
    icon: "i-simple-icons-facebook",
    click: navigateBlank("https://www.facebook.com/techmely"),
    badge: "5k",
  },

  {
    id: "timvietfe-group",
    label: "Group tìm việc FrontEnd",
    icon: "i-simple-icons-angular",
    click: navigateBlank("https://www.facebook.com/groups/timviecfe/"),
    badge: "8.1k",
  },
  {
    id: "congdongfe-group",
    label: "Tìm việc Backend, Devops",
    icon: "i-simple-icons-nodedotjs",
    click: navigateBlank("https://www.facebook.com/groups/timviecbackend"),
    badge: "5k",
  },
]);

const tiktok = computed(() => [
  {
    id: "tiktok",
    label: "TechMely",
    icon: "i-simple-icons-tiktok",
    click: navigateBlank("https://www.tiktok.com/@techmely"),
    badge: `6.5k ${LL.value.follow()}`,
  },
]);

const links = computed(() => [
  ...youtube.value,
  ...tiktok.value,
  ...discord.value,
  ...github.value,
  ...facebook.value,
]);
</script>
